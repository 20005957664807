$add-btn: url("./../../assets/images/custom/v1/plus-2-off.png");
$add-btn-hover: url("./../../assets/images/custom/v1/plus-2-on.png");
 
#root{
  position: relative;
}
#status2{
  margin-top: 180px;
}

.aok-datepicker {
  float: right;

  a {    
    margin-top: -3px;
    font-size: 16px;
  }
}

.nts {
  width: 80%;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .a-mts-15{
    margin: -15px 0 -15px 0;
  }
  .aok-datepicker {
    display: flex !important;
    width: 100%;
    a {    
      margin-top: -3px;
      font-size: 24px;
      padding-left: 10px;
      padding-right: 10px;
      width: 30px;
    }
    .search-label {
      float: none;
      width: 100% !important;
      min-width: 180px;
      .form-control {
        border-radius: 0.25rem !important;
        text-align: center;
        padding-right: 45px; 
      }
    }
  }

  .nts {
    width: 100%;
    overflow: hidden;
  }
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
 

.custom-checkbox:hover,
.custom-checkbox-label:hover {
  cursor: pointer;
}
.custom-checkbox-img {
  height: 25px;
}
.custom-checkbox-label {
  margin-left: 5px;
}

.add-btn {
  .add-btn-img {
    content: $add-btn;
    height: 50px;
  }
  .add-btn-label {
    margin-right: 5px;
  }

  :hover {
    cursor: pointer;
  }
  &:active {
    .add-btn-img {
      content: $add-btn-hover;
    }
    .add-btn-label {
      color: #49507dd5;
    }
  }
}
.default-btn {
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
}

.btn-35 {
  height: 35px;
}

.btn-excel-passive {
  background-color: #d1d1d1 !important;
  color: #0ecb00 !important;
}

.custom-header-css thead tr th div:last-child {
  min-height: 20px !important;
}
 
#search-bar-0 {
  width: 30em;
}
.form-control {
  box-shadow: inset 0px 2px 10px 0 rgba(0, 0, 0, 0.2),
    inset 0px -2px 10px 0 rgba(255, 255, 255, 0.2) !important;
}


.table-responsive table th:last-child, .table-responsive table tbody td:last-child {
  text-align: end;
}

.r-0{
  right: 0;
}


.page-select{
  width: 75px;
  max-width: 75px;
  min-width: 75px;
  text-align: end;
}
