$img_down: url("./../../../assets/images/custom/v1/down.png");
$img_calender: url("./../../../assets/images/custom/v1/calendar-off.png");
$img_close: url("./../../../assets/images/custom/v1/plus-d-on.png");
$img_code: url("./../../../assets/images/custom/v1/code.png");

$img_radio_off: url("./../../../assets/images/custom/v1/radio-off.png");
$img_radio_on: url("./../../../assets/images/custom/v1/radio-on.png");
$img_add: url("./../../../assets/images/custom/v1/plus-off.png");
$img_add_hover: url("./../../../assets/images/custom/v1/plus-on.png");
$img_remove: url("./../../../assets/images/custom/v1/minus-off.png");
$img_remove_hover: url("./../../../assets/images/custom/v1/minus-on.png");

.card {
  border-radius: 10px !important;
}

.coupon-img {
  object-fit: contain;
  width: 100%;
  height: 200px;
  max-height: 200px;
  max-width: 100%;
}
.brand-img {
  object-fit: contain;
  height: 40px;
  max-width: 100%;
}
.date-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 195px;
}
.date-title {
  font-weight: bold;
  text-align: left;
  color: #707070;
}
.date {
  width: 75px;
  word-break: break-all;
  color: #707070;
}

.title {
  font-weight: bold;
  word-break: break-all;
  color: #707070;
}
.description {
  color: #707070;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
.description-max {
  word-break: break-all;
}
.img-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img-back {
  height: 24px;
  object-fit: contain;
  margin-right: 15px;
}
.img-forward {
  height: 16px;
  object-fit: contain;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
.relative {
  position: relative;
}

.a-btn {
  font-family: Roboto, sans-serif;
  font-weight: 0;
  font-size: 14px;
  color: #313f84;
  background-color: #ffffff;
  padding: 10px 30px;
  box-shadow: rgba(171, 171, 171, 0.17) 0px -4px 4px 0px inset,
    rgba(171, 171, 171, 0.15) 0px -4px 4px 0px inset,
    rgba(171, 171, 171, 0.1) 0px -4px 4px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
    rgba(0, 0, 0, 0.09) 0px -1px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 4px;
  border-radius: 10px;
  transition: 120ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  &:active {
    transition: 120ms;
    padding: 10px 46px;
    transform: translateY(-0px);
    color: #707070;
    box-shadow: rgba(171, 171, 171, 0.17) -3px 4px 4px 2px inset,
      rgba(171, 171, 171, 0.15) 3px -2px 5px 2px inset,
      rgba(171, 171, 171, 0.1) -3px 1px 0px 0px inset,
      rgba(0, 0, 0, 0.1) 0px 2px 1px, rgba(171, 171, 171, 0.1) -1px 5px 3px,
      rgba(0, 0, 0, 0.1) 0px -1px 0px, rgba(171, 171, 171, 0.1) 0px 4px 1px,
      rgba(0, 0, 0, 0.1) 0px 4px 4px;
    img {
      filter: grayscale(25%); //sepia(100%) hue-rotate(50deg) saturate(100%);
    }
  }
}

.group-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}
.group-item {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.aok-border-danger {
  border-radius: 5px;
  border: 1px solid #c02223;
}

.form-select {
  border-radius: 10px !important;
  background-image: none !important;
  background-position: right -1.25rem center !important;
  background-size: 50px 50px !important;
  box-shadow: rgba(171, 171, 171, 0.17) 0px 0px 4px 0px inset,
    rgba(171, 171, 171, 0.15) 0px -4px 4px 0px inset,
    rgba(171, 171, 171, 0.1) 0px -4px 4px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
    rgba(0, 0, 0, 0.09) 0px -1px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 4px;
}
.form-select-img {
  content: $img_down !important;
  position: absolute;
  right: -8px;
  top: 18px;
  height: 56px;
}
.form-select-img-9 {
  content: $img_down !important;
  position: absolute;
  right: -8px;
  top: -9px;
  height: 56px;
}
.form-date-img {
  content: $img_calender !important;
  position: absolute;
  right: -8px;
  top: -9px;
  height: 56px;
}

.form-code-img {
  content: $img_code !important;
  position: absolute;
  right: 4px;
  top: 20px;
  height: 56px;
}

.form-control {
  border-radius: 10px !important;
  box-shadow: rgba(171, 171, 171, 0.17) 0px 0px 4px 0px inset,
    rgba(171, 171, 171, 0.15) 0px -4px 4px 0px inset,
    rgba(171, 171, 171, 0.1) 0px -4px 4px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
    rgba(0, 0, 0, 0.09) 0px -1px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 4px;
}

.ck-editor__editable_inline {
  min-height: 230px;
  max-height: 230px;
}

.border-dashed {
  border: 2px dashed var(--bs-border-color) !important;
  border-radius: 6px !important;
}

.dropzone-previews {
  padding: 0 !important;
}
#file-previews {
  max-height: 230px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  max-width: 100%;
  max-height: 100%;
  img {
    height: 170px;
    max-height: 80%;
    max-width: 100%;
    object-fit: contain;
  }
}
.dropzone-close {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 9;
  :hover {
    cursor: pointer;
  }
  img {
    content: $img_close;
    height: 40px !important;
    width: 40px !important;
  }
}

.border-radius-20 {
  border-radius: 20px;
}
.term-btn {
  :hover {
    cursor: pointer;
  }
  img {
    content: $img_radio_off;
    height: 40px !important;
    width: 40px !important;

    &.active {
      content: $img_radio_on;
    }
  }

  .term-container {
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 10px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: rgba(171, 171, 171, 0.17) 0px -4px 4px 0px inset,
      rgba(171, 171, 171, 0.15) 0px -4px 4px 0px inset,
      rgba(171, 171, 171, 0.1) 0px -4px 4px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
      rgba(0, 0, 0, 0.09) 0px -1px 1px, rgba(171, 171, 171, 0.09) 0px 4px 4px,
      rgba(0, 0, 0, 0.09) 0px 4px 4px;
    &:active {
      box-shadow: rgba(171, 171, 171, 0.17) -3px 4px 4px 2px inset,
        rgba(171, 171, 171, 0.15) 3px -2px 5px 2px inset,
        rgba(171, 171, 171, 0.1) -3px 1px 0px 0px inset,
        rgba(0, 0, 0, 0.1) 0px 2px 1px, rgba(171, 171, 171, 0.1) -1px 5px 3px,
        rgba(0, 0, 0, 0.1) 0px -1px 0px, rgba(171, 171, 171, 0.1) 0px 4px 1px,
        rgba(0, 0, 0, 0.1) 0px 4px 4px;
    }
  }
  label {
    font-size: 1.5rem;
    font-weight: bold;
    color: #393185;
  }
  p {
    color: #00a6d6;
  }
}

.btn-add {
  img {
    content: $img_add;
    height: 40px !important;
    width: 40px !important;
  }
  :hover {
    cursor: pointer;
  }
  &:active {
    content: $img_add_hover;
    height: 40px !important;
    width: 40px !important;
  }
}

.btn-remove {
  img {
    content: $img_remove;
    height: 40px !important;
    width: 40px !important;
  }
  :hover {
    cursor: pointer;
  }
  &:active {
    content: $img_remove_hover;
    height: 40px !important;
    width: 40px !important;
  }
}

.modal-table-container {
  display: flex;
  flex-direction: row;
  justify-content: center; 
}
.modal-table-left{
  text-align: left;
  padding-right: 10px;
}  
.puank-desc{ 
  word-break: break-word; 
}
.tbl-puank{
  min-width: 100px;
}
 
.sketch-picker{
  right: 0;
}