#root {
  position: relative;
}
.custom-picker {
  border-radius: 0.25rem !important;
  padding-right: 20px !important;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.page-content {
  min-height: 600px;
}

.table tbody tr td {
  text-wrap: nowrap;
}

.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}

.rotate-90 {
  vertical-align: middle;
  text-align: left !important;
  padding-top: 10px;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  width: 44px;
}

.no-wrap {
  text-wrap: nowrap;
}

.custom-tr-gray tr:nth-child(even) {
  background-color: #dfdfdf;
}
