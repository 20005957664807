@media (max-width: 600px) {
  .mobile-nav { 
    position: fixed;
    bottom: 0;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #f8f8fb;
    z-index: 99;
    font-size: 40px;
  }
}
