.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.mini-stats-wid .mini-stat-icon {
  height: 48px !important;
  width: 48px !important;
  max-height: 48px !important;
  max-width: 48px !important;
  min-height: 48px !important;
  min-width: 48px !important;
}

.fw-desc{
    font-size: medium;
}

.custom_hover:hover{
  background-color: #dfdfdf;
}